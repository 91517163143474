import {
  Collapse,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useAppContext } from "../../../ApplicationContext";
import { NavbarModes } from "../../../enums";
import { FC, ReactNode } from "react";
import theme from "../../../themes/light";

export const LeftNavBarItem: FC<
  ListItemButtonProps & {
    expandable?: boolean;
    open?: boolean;
    label: string;
    icon: ReactNode;
    selected: boolean;
  }
> = ({ open, expandable, label, icon, selected, ...props }) => {
  const CaretComp = open ? ArrowDropUp : ArrowDropDown;
  const { navbarMode } = useAppContext();
  const isOpen = navbarMode === NavbarModes.EXPENDED;

  return (
    <ListItemButton {...props}
      sx={{
        maxHeight: "35px",
        background: selected ? theme.palette.primaryPartial[600] : "",
        ":hover": {
          background: selected ? theme.palette.primaryPartial[600] : ""
        },
        ":focus": {
          background: selected ? theme.palette.primaryPartial[600] : ""
        }
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: "37.5px",
          alignItems: "center",
          
          // "& svg": {
          //   path: {
          //     fill: selected
          //       ? theme.palette.backgrounds.white
          //       : theme.palette.text.info,
          //   },
          // },
        }}
      >
        {icon}
      </ListItemIcon>
      <Collapse in={isOpen}>
        <ListItemText
          primary={
            <Typography
              width="130px"
              // variant="body1"
              fontWeight={selected ? 600 : 400}
              fontSize="14px"
              noWrap
              title={label}
              color={`${theme.palette.backgrounds.white} !important`}
              // color={
              //   selected
              //     ? `${theme.palette.backgrounds.white} !important`
              //     : theme.palette.text.info
              // }
            >
              {label}
            </Typography>
          }
        />
      </Collapse>
      {expandable && <CaretComp />}
    </ListItemButton>
  );
};
