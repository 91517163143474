import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import SVG from "react-inlinesvg";
import { useAppContext } from "../ApplicationContext";
import { NavbarModes } from "../enums";
import { FC } from "react";

export const NavbarExpandIcon = () => {
  const theme = useTheme();
  return <SVG fill={theme.palette.text.link} src={"/nav-expand-icon.svg"} />;
};

export const NavbarCollapseIcon = () => {
  const theme = useTheme();
  return <SVG fill={theme.palette.text.link} src={"/nav-collapse-icon.svg"}/>;
};

const NavbarIndicator: FC<object> = () => {
  const { navbarMode, setNavbarMode } = useAppContext();
  const onClick = () => {
    setNavbarMode(
      navbarMode === NavbarModes.EXPENDED
        ? NavbarModes.COLLAPSED
        : NavbarModes.EXPENDED
    );
  };
  return (
    <Button
      fullWidth
      onClick={() => onClick()}
      color="secondary"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {navbarMode === NavbarModes.COLLAPSED ? (
        <NavbarCollapseIcon />
      ) : (
        <NavbarExpandIcon />
      )}
    </Button>
  );
};

export default NavbarIndicator;
