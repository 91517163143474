import { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";

const FadedText: FC<{ text: string; open: boolean }> = ({ text, open }) => {
  const [isVisible, setIsVisible] = useState(open);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      setTimeout(() => setIsFading(true), 0);
    } else {
      setIsFading(false);
      setTimeout(() => setIsVisible(false), 500);
    }
  }, [open]);

  return isVisible ? (
    <Typography
      color="#FFFFFF"
      fontWeight={500}
      fontSize="12px"
      sx={{
        minWidth: "200px",
        minHeight: "30px",
        opacity: isFading ? 1 : 0,
        transition: "opacity 0.5s ease",
      }}
    >
      {text}
    </Typography>
  ) : null;
};

export default FadedText;
